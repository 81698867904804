import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactstrap/lib";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import FacebookChatPopUp from "./components/FacebookChatPopUp";
import ScrollTopp from "./components/ScrollTop";
import AnnouncementBar from "./components/AnnouncementBar";
import NavBar from "./components/NavBar";
import Blogs from "./pages/Blogs";
import BlogPage from "./pages/Blogs/BlogPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import TermsAndConditions from "./pages/TermsAndConditions";
import Disclaimer from "./pages/Disclaimer";

const messages = [
  '🎉 <a href="https://www.facebook.com/people/GAME-VAULT/61564442363000/" target="_blank" style="color: yellow; text-decoration: underline;">Sign up</a> today and receive an incredible Cash Back or Welcome Bonus on your first deposit!',
  '🎉 <a href="https://www.facebook.com/people/GAME-VAULT/61564442363000/" target="_blank" style="color: yellow; text-decoration: underline;">Sign up</a> today and receive an incredible Cash Back or Welcome Bonus on your first deposit!',
];

const App = () => {
  return (
    <>
      <div className="app-bg">
        <AnnouncementBar messages={messages} />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:title" element={<BlogPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
        <Footer />
        <FacebookChatPopUp />
        <ScrollTopp />
      </div>
    </>
  );
};

export default App;
