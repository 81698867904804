import React from "react";
import "../styles/Banner.css";

const Banner = () => {
  return (
    <>
      <div className="background-container">
        <div className="banner-container">
          <h1 className="banner-online">
            Download Game Vault App & Play Free Online Casino Games!
          </h1>
          {/* <div className="banner-orion-star">Game Vault  in an all new way to play your favorite type of sweepstakes, reels, and fish games on an app! You can play the fish and sweepstakes games at your “go to spot” in your city, home, or anywhere you like. Your credits are tied to your account. Bottom line, you can play on any device, anytime, anywhere!</div> */}
        </div>
        <div className="banner-container-two">
          <div className="banner-button-wrapper">
            <button
              className="banner-button"
              onClick={() =>
                window.open("http://download.gamevault999.com", "_blank")
              }
            >
              Join Now!
            </button>
          </div>
          <div className="banner-so-whats">
            The Game Vault Casino App does just that. It gives access to
            thrilling bonuses and high-quality online casino games. It's a
            casino platform where players can expect various fun features.
            Choose games that suit your playing style, such as slot games and
            fish games. Spin, Win, and Enjoy Big Rewards! 🎰 Play Now at
            GameVault777Slots!"
          </div>
        </div>
        <div className="banner-container-three">
          <div className="now-play">NOW PLAY DIRECTLY WITH US!</div>
        </div>
        {/* <div className="banner-img-bg" /> */}
      </div>
    </>
  );
};

export default Banner;
