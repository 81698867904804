import "../styles/Announcement.css";

const AnnouncementBar = ({ messages }) => {
  return (
    <div className="announcement-container">
      <div
        className="announcement-text"
        dangerouslySetInnerHTML={{ __html: messages[0] }}
      ></div>
      <div
        className="announcement-text"
        dangerouslySetInnerHTML={{ __html: messages[1] }}
      ></div>
    </div>
  );
};

export default AnnouncementBar;
